import { all, call, put, takeEvery, select } from 'redux-saga/effects';

import { uploadFailed } from 'src/components/alert/slice';
import { getRegionSetting } from 'src/selectors';
import { toSafeUploadName, uploadImageAndGetUrl } from 'src/util/upload';
import { cleanTexture } from 'src/services/sceneClient';
import { logError } from 'src/loggingManager';
import { cylinderWarp } from 'src/services/renderingClient';
import { getCylinderWarpById, getSceneSize } from 'src/selectors/layers';
import { getTransformsById } from 'src/selectors/transforms';
import { PERSPECTIVE_TRANSFORM, TUBE_TRANSFORM, PLACEMENT_TRANSFORM } from 'src/models/transforms';
import { CYLINDER_WARP } from 'src/models/documentWarps';
import {
    removeMask,
    uploadMask,
    maskUploaded,
    setWarp,
    replaceTransforms,
    updateCylinder,
    toggleFullWrap,
    updatePrecisionWrap,
    rotateCylinder,
    textureUploaded,
    removeTexture,
    uploadTexture,
} from './slice';

function createCylinderWarpParameters(width, height) {
    // Setup dimension & position values.
    const middleX = width / 2;
    const topY = height * 0.3;
    const bottomY = height * 0.7;
    const ellipseWidth = width * 0.2;
    const ellipseHeight = height * 0.05;

    const productDiameter = width * 0.5;
    const decorationAreaSize = (productDiameter * Math.PI) / 4;
    return {
        cylinderTop: {
            center: {
                x: middleX,
                y: topY,
            },
            radiusX: ellipseWidth,
            radiusY: ellipseHeight,
            rotationDegrees: 0.0,
        },
        cylinderBottom: {
            center: {
                x: middleX,
                y: bottomY,
            },
            radiusX: ellipseWidth,
            radiusY: ellipseHeight,
            rotationDegrees: 0.0,
        },
        decorationAreaDimensions: {
            width: decorationAreaSize,
            height: decorationAreaSize,
        },
        productDiameter,
        decorationExtentDegreesAlpha: 225,
        decorationExtentDegreesBeta: 90,
        isDecorationRotated: false,
        imageDimensions: {
            width,
            height,
        },
    };
}

/**
 * @param {File} payload.file
 * @param {string} payload.id
 */
function* uploadAndAddMask({ payload }) {
    const { file, id } = payload;
    const region = yield select(getRegionSetting);
    const sceneSize = yield select(getSceneSize);
    try {
        const url = yield call(uploadImageAndGetUrl, file, region, sceneSize);
        yield put(
            maskUploaded({
                url,
                id,
                fileName: toSafeUploadName(file.name),
            }),
        );
    } catch (error) {
        logError(
            `unable to upload mask, there was an error while uploading/updating: ${error} ${file.name} ${file.size} ${file.type}`,
        );
        yield put(uploadFailed(`Mask: ${error}`));
        yield put(removeMask(id));
    }
}

/**
 * @param {File} payload.file
 * @param {string} payload.id
 */
function* uploadAndAddTexture({ payload }) {
    const { file, id } = payload;
    const region = yield select(getRegionSetting);
    try {
        const cleanedTexture = yield call(cleanTexture, { texture: file });
        const url = yield call(uploadImageAndGetUrl, cleanedTexture, region);
        yield put(
            textureUploaded({
                url,
                id,
                fileName: file.name,
            }),
        );
    } catch (error) {
        logError(
            `unable to upload texture, there was an error while uploading/updating: ${error} ${file.name} ${file.size} ${file.type}`,
        );
        yield put(uploadFailed(`Texture: ${error}`));
        yield put(removeTexture(id));
    }
}

/**
 * @param {string} payload.id the document id
 */
function* uploadCylinderWarp(id) {
    const warp = yield select(getCylinderWarpById, id);
    const documentTransforms = yield select(getTransformsById, id);
    const perspectiveId = documentTransforms.find((transform) => transform.type === PERSPECTIVE_TRANSFORM).id;
    const cylinderId = documentTransforms.find((transform) => transform.type === TUBE_TRANSFORM).id;
    const placement = documentTransforms.find((transform) => transform.type === PLACEMENT_TRANSFORM);
    const placementId = placement ? placement.id : null;
    const response = yield call(cylinderWarp, warp, id, perspectiveId, cylinderId, placementId);
    if (response.perspectiveWarp && response.tubeWarp && response.documentSize) {
        const transforms = response.placementWarp
            ? [response.perspectiveWarp, response.tubeWarp, response.placementWarp]
            : [response.perspectiveWarp, response.tubeWarp];
        yield put(
            replaceTransforms({
                id,
                transforms,
                width: response.documentSize.width,
                height: response.documentSize.height,
            }),
        );
    }
}

/**
 * @param {string} payload.id the document id
 * @param {string} payload.width the scene width
 * @param {string} payload.height the scene height
 */
function* createCylinderWarp({ payload }) {
    const { id, width, height, warpType } = payload;
    if (warpType === CYLINDER_WARP) {
        const cylinder = createCylinderWarpParameters(width, height);
        yield put(updateCylinder({ id, cylinder }));
    }
}

/**
 * @param {string} payload.id the document id
 * @param {string} payload.width the width of the design surface
 * @param {string} payload.diameter the diameter of the product that is being wrapped by the surface
 */
function* uploadPrecisionWrap({ payload }) {
    const { id, width, diameter } = payload;
    if (width > 0 && diameter > 0) {
        yield call(uploadCylinderWarp, id);
    }
}

/**
 * @param {string} payload the document id
 */
function* uploadFromId({ payload }) {
    yield call(uploadCylinderWarp, payload.id || payload);
}

export default function* documentsSaga() {
    return yield all([
        yield takeEvery(uploadMask, uploadAndAddMask),
        yield takeEvery(setWarp, createCylinderWarp),
        yield takeEvery(updateCylinder, uploadFromId),
        yield takeEvery(toggleFullWrap, uploadFromId),
        yield takeEvery(rotateCylinder, uploadFromId),
        yield takeEvery(updatePrecisionWrap, uploadPrecisionWrap),
        yield takeEvery(uploadTexture, uploadAndAddTexture),
    ]);
}
