import React, { PureComponent } from 'react';
import { Button, Modal } from '@cimpress/react-components';
import { PERSPECTIVE_WARP, RECTANGLE_WARP, SMOOTH_WARP } from 'src/models/documentWarps';
import { downloadImage } from 'src/util/upload';
import SourcePointCanvas from './sourcePointCanvas';

const PERCENTAGE = 0.7;
const MODAL_PADDING = 40;
const OFFSET = 50;
const DEFAULT_LENGTH = 500;
const TRANSIENT_SCENE = 'https://scenes.documents.cimpress.io/v3/transient?data=';

type Point = {
    x: number;
    y: number;
};

type MapPoint = Point & {
    sourceX: number;
    sourceY: number;
};

type SourcePointProps = {
    id: string;
    points: MapPoint[];
    warpType: string;
    onApply: (data: { id: string; points: MapPoint[]; skipDelay: boolean }) => void;
    surfaceData: any;
    previewUrl?: string;
};

type Img = {
    src: string;
    scaledWidth: number;
    scaledHeight: number;
    width: number;
    height: number;
};

type SourcePointState = {
    showSourcePointEditor: boolean;
    points: MapPoint[];
    img: Img;
    snapCoordinates: { x: number[]; y: number[] };
};

export default class SourcePoint extends PureComponent<SourcePointProps, SourcePointState> {
    state: SourcePointState = {
        showSourcePointEditor: false,
        points: this.props.points,
        img: {
            src: '',
            scaledWidth: DEFAULT_LENGTH,
            scaledHeight: DEFAULT_LENGTH,
            width: DEFAULT_LENGTH,
            height: DEFAULT_LENGTH,
        },
        snapCoordinates: { x: [], y: [] },
    };

    componentDidMount() {
        const { previewUrl } = this.props;

        if (previewUrl) {
            this.calculateDimensionAndSnapCoordinates(previewUrl);
        }
        window.addEventListener('resize', this.resizeEditor);
    }

    componentDidUpdate(prevProps: SourcePointProps) {
        const { previewUrl, points } = this.props;

        // preview has changed, recalculate image dimension and snap coordinates
        if (previewUrl && prevProps.previewUrl !== previewUrl) {
            this.calculateDimensionAndSnapCoordinates(previewUrl);
        }

        // source points have changed, reset state to new set of points
        let pointsChanged = false;
        if (prevProps.points.length === points.length) {
            for (let i = 0; i < points.length; i++) {
                if (
                    prevProps.points[i].sourceX !== points[i].sourceX ||
                    prevProps.points[i].sourceY !== points[i].sourceY
                ) {
                    pointsChanged = true;
                    break;
                }
            }
        } else {
            pointsChanged = true;
        }

        if (pointsChanged) {
            this.setPoints(points);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeEditor);
    }

    setPoints = (points: MapPoint[]): void => {
        this.setState({ points });
    };

    calculateDimensionAndSnapCoordinates = (previewUrl: string): void => {
        const { surfaceData } = this.props;

        let ratio = 1.3;
        let page = 1;

        if (surfaceData) {
            ratio = surfaceData.width / surfaceData.height;
            page = surfaceData.index;
        }

        // remove scene from document preview
        const urlSegments = previewUrl.split('&').filter((segment) => segment.substring(0, 5) !== 'scene');
        urlSegments.push(
            `scene=${TRANSIENT_SCENE}${encodeURIComponent(JSON.stringify({ width: Math.floor(DEFAULT_LENGTH * ratio), height: DEFAULT_LENGTH, page }))}`,
        );
        urlSegments.push('bgColor=ffffff'); // adds white background to preview image
        previewUrl = urlSegments.join('&'); // eslint-disable-line no-param-reassign

        downloadImage(previewUrl).then((img) => {
            this.resizeImage(img);
        });
    };

    resizeImage = (img: { src: string; width: number; height: number }) => {
        const { surfaceData } = this.props;
        // calculate max image size could be based on window size
        const maxWidth = window.innerWidth * PERCENTAGE - OFFSET * 2 - MODAL_PADDING;
        const maxHeight = window.innerHeight * PERCENTAGE - OFFSET * 2 - MODAL_PADDING;

        // determine how much the image need to be scaled
        let scale = 1;
        if (img.width > maxWidth) {
            scale = maxWidth / img.width;
        }
        if (img.height * scale > maxHeight) {
            scale = maxHeight / img.height;
        }

        const scaledWidth = img.width * scale;
        const scaledHeight = img.height * scale;

        // snap points to document border
        const snapCoordinates = {
            x: [OFFSET, scaledWidth + OFFSET],
            y: [OFFSET, scaledHeight + OFFSET],
        };

        if (surfaceData) {
            const trim = surfaceData.trimArea ?? { x: 0, y: 0, width: surfaceData.width, height: surfaceData.height };

            // Compute the trim values
            const left = (trim.x / surfaceData.width) * scaledWidth + OFFSET;
            const top = (trim.y / surfaceData.height) * scaledHeight + OFFSET;
            const right = (1.0 - (surfaceData.width - trim.x + trim.width) / surfaceData.width) * scaledWidth + OFFSET;
            const bottom =
                (1.0 - (surfaceData.height - trim.y + trim.height) / surfaceData.height) * scaledHeight + OFFSET;

            // snap points to document trim
            snapCoordinates.x = snapCoordinates.x.concat([left, right]);
            snapCoordinates.y = snapCoordinates.y.concat([top, bottom]);

            // Compute fold line values
            if (surfaceData.foldLines) {
                surfaceData.foldLines.forEach((fold: any) => {
                    // horizontal fold
                    if (fold.startY === fold.endY) {
                        snapCoordinates.y.push((fold.startY / surfaceData.height) * scaledHeight + OFFSET);
                    }
                    // vertical fold
                    if (fold.startX === fold.endX) {
                        snapCoordinates.x.push((fold.startX / surfaceData.width) * scaledWidth + OFFSET);
                    }
                });
            }
        }

        this.setState({
            img: { src: img.src, width: img.width, height: img.height, scaledWidth, scaledHeight },
            snapCoordinates,
        });
    };

    resizeEditor = () => {
        const { img } = this.state;
        this.resizeImage(img);
    };

    closeEditor = () => {
        this.setState({ showSourcePointEditor: false });
    };

    openEditor = () => {
        this.setState({ showSourcePointEditor: true });
    };

    updateSourcePoints = () => {
        const { points } = this.state;
        const { onApply, id } = this.props;

        // close modal
        this.closeEditor();

        onApply({ id, points, skipDelay: true });
    };

    onDragEnd = (newPoints: Point[]) => {
        const { img } = this.state;
        const { points } = this.props;

        const updatedPoints = [];

        // calculate new source points for each point
        for (let i = 0; i < newPoints.length; i++) {
            const ratioX = (newPoints[i].x - OFFSET) / img.scaledWidth;
            const ratioY = (newPoints[i].y - OFFSET) / img.scaledHeight;

            updatedPoints.push({ x: points[i].x, y: points[i].y, sourceX: ratioX, sourceY: ratioY });
        }
        this.setPoints(updatedPoints);
    };

    render() {
        const { warpType, id } = this.props;
        const { showSourcePointEditor, img, snapCoordinates, points } = this.state;
        const acceptableWarps = [RECTANGLE_WARP, PERSPECTIVE_WARP, SMOOTH_WARP];
        if (!acceptableWarps.includes(warpType)) {
            return null;
        }

        return (
            <div>
                <Button style={{ marginBottom: '30px' }} onClick={this.openEditor}>
                    Source Point Editor
                </Button>
                <Modal
                    show={showSourcePointEditor}
                    footer={
                        <>
                            <Button onClick={this.closeEditor}>Cancel</Button>
                            <Button onClick={this.updateSourcePoints}>Apply</Button>
                        </>
                    }
                    style={{ width: `${img.scaledWidth + OFFSET * 2 + MODAL_PADDING}px` }}>
                    <SourcePointCanvas
                        id={id}
                        points={points}
                        warpType={warpType}
                        onDragEnd={this.onDragEnd}
                        offset={OFFSET}
                        snapCoordinates={snapCoordinates}
                        img={img}
                    />
                </Modal>
            </div>
        );
    }
}
